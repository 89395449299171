.App {
  text-align: center;
}

.App-logo {

}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #0b0b0b;
}

.App-link {
  color: #09d3ac;
}
